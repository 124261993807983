<template>
  <v-container v-if="!inviteLoading" class="full-height-container px-6">
    <v-row>
      <v-col cols="12" class="d-flex align-center flex-column login-container">
        <v-img
        v-if="isLogoBase64 || !customLogo"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
          contain
          :src="customLogo ? customLogo : require('@/assets/logo.svg')"
          class="mb-10"
        ></v-img>
        <div v-else v-html="customLogo" class="mb-10 d-flex" 
            >
            </div>
        <v-card v-if="inviteError" elevation="4" class="d-flex">
          <v-card-title class="d-flex justify-center">
            <h1 class="font-weight-800">{{ $t('heading.acceptInvitePage.error.title') }}</h1>
          </v-card-title>
          <v-card-text>{{ $t('heading.acceptInvitePage.error.info') }}</v-card-text>
        </v-card>
        <v-card v-else-if="passwordRequired" elevation="4" class="d-flex">
          <v-card-title class="d-flex justify-center">
            <h5
              class="font-weight-800"
              style="font-size: 22px !important"
            >{{ $t('heading.acceptInvitePage.password.title') }}</h5>
          </v-card-title>
          <v-card-text>
            <template v-if="errors.length">
              <collapse-transitions>
                <div
                  class="login-error-message mb-6"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  <div class="login-error-message__icon-box">
                    <v-icon size="24" color="error">$alertwarning</v-icon>
                  </div>
                  <div class="login-error-message__text-box">
                    <h6 class="p-3 login-error-message__title">
                      {{ error }}
                    </h6>
                    <p
                      v-if="$store.state.home.app.support_link"
                      class="mb-0 p-3 login-error-message__desc"
                    >
                      {{ $t('message.support.before') }}
                      <a :href="$store.state.home.app.support_link" class="info--text">{{ $t('message.support.link') }}</a>
                      {{ $t('message.support.after') }}
                    </p>
                  </div>
                </div>
              </collapse-transitions>
            </template>
            <v-form class="form" ref="form">
              <v-text-field
                outlined
                class="v-input--lg"
                :label="$t('form.label.password')"
                type="password"
                v-model="password"
                :rules="adminPasswordRules"
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$lockoutline</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                outlined
                class="v-input--lg"
                :label="$t('form.label.passwordConfirm')"
                type="password"
                v-model="password_confirmation"
                :rules="adminPasswordRules"
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$lockoutline</v-icon>
                </template>
              </v-text-field>
            </v-form>

            <v-btn
              x-large
              @click.prevent="register()"
              color="primary"
              block
              elevation="0"
              class="mt-6"
              :loading="loading"
            >
              <span class="font-weight-600">{{ $t('button.register') }}</span>
              <template v-slot:loader>
                <loader :color="'white'" :size="24" />
              </template>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-img
      :src="require('@/assets/illustrations/radio-lines.svg')"
      class="radio-lines"
    />
  </v-container>
</template>

<script>
import CollapseTransitions from "../components/transitions/CollapseTransitions.vue";

import FormRulesMixin from "../mixins/FormRulesMixin";
import Loader from "../components/Loader.vue";
import Api from "@/apis/Api";

export default {
  components: {
    CollapseTransitions,
    Loader,
  },
  mixins: [FormRulesMixin],
  data: function () {
    return {
      token: this.$route.query.token,
      password: "",
      password_confirmation: "",
      errors: [],
      loading: false,
      inviteLoading: true,
      inviteError: false,
      passwordRequired: false,
    };
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }
      return this.$store.state.ThemeModule.logo;
    },
    isLogoBase64: function() {
      return this.customLogo &&  this.customLogo.trim().startsWith('data:image/');
    }
  },
  mounted: function () {
    Api.post("/accept-invitation", {
      token: this.token,
    })
      .then((response) => {
        if (response.data.password_required) {
          this.passwordRequired = true;
        } else if (response.data.login_required) {
          this.$router.push("/login?invite_token=" + this.token);
        } else {
          this.$router.push("/");
        }
      })
      .catch(() => {
        this.inviteError = true;
      })
      .finally(() => {
        this.inviteLoading = false;
      });
  },
  methods: {
    register() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      Api.post("/accept-invitation", {
        token: this.token,
        password: this.password,
        password_confirmation: this.password_confirmation,
      })
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          this.errors = [Api.getErrorMessage(error)];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  z-index: 999;
}

.full-height-container {
  display: flex;
  align-items: center;
  height: 100vh;
}

.radio-lines {
  position: absolute;
  top: 0;
  left: 0;
}

.v-card::v-deep {
  max-width: 420px;
  width: 100%;
  .v-card {
    &__text,
    &__title {
      padding-left: 48px;
      padding-right: 48px;
    }
    &__text {
      overflow: visible;
      padding-bottom: 48px;
      padding-top: 0px;
      -webkit-line-clamp: unset !important;
    }
    &__title {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  .v-input.v-input--checkbox {
    .v-input__slot {
      min-height: unset;
      max-height: unset;
    }
  }
}

.form {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.v-input::v-deep .v-input__control .v-input__slot .v-input__prepend-inner {
  margin-top: 2px;
}

.login-error-message {
  background: linear-gradient(
    90deg,
    rgba(241, 40, 40, 0.06) 0%,
    rgba(241, 40, 40, 0) 33.43%
  );
  border-radius: 6px;
  border: 1px solid map-get($error, base);
  border-left-width: 4px;
  padding: 16px;
  display: flex;
  &__icon-box {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }
  &__text-box {
    display: flex;
    flex-direction: column;
  }
  &__title {
    margin-bottom: 8px;
    color: map-get($text, heading);
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .v-card::v-deep {
    .v-card {
      &__title,
      &__text {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}
</style>